exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-generated-pages-tsx": () => import("./../../../src/pages/generated-pages.tsx" /* webpackChunkName: "component---src-pages-generated-pages-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/About.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-case-detail-tsx": () => import("./../../../src/templates/CaseDetail.tsx" /* webpackChunkName: "component---src-templates-case-detail-tsx" */),
  "component---src-templates-cases-category-tsx": () => import("./../../../src/templates/CasesCategory.tsx" /* webpackChunkName: "component---src-templates-cases-category-tsx" */),
  "component---src-templates-cases-tsx": () => import("./../../../src/templates/Cases.tsx" /* webpackChunkName: "component---src-templates-cases-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-employee-detail-tsx": () => import("./../../../src/templates/EmployeeDetail.tsx" /* webpackChunkName: "component---src-templates-employee-detail-tsx" */),
  "component---src-templates-employees-tsx": () => import("./../../../src/templates/Employees.tsx" /* webpackChunkName: "component---src-templates-employees-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-jobs-tsx": () => import("./../../../src/templates/Jobs.tsx" /* webpackChunkName: "component---src-templates-jobs-tsx" */),
  "component---src-templates-kennisbank-tsx": () => import("./../../../src/templates/Kennisbank.tsx" /* webpackChunkName: "component---src-templates-kennisbank-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-detail-tsx": () => import("./../../../src/templates/PostDetail.tsx" /* webpackChunkName: "component---src-templates-post-detail-tsx" */),
  "component---src-templates-posts-category-tsx": () => import("./../../../src/templates/PostsCategory.tsx" /* webpackChunkName: "component---src-templates-posts-category-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/Posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */),
  "component---src-templates-service-category-tsx": () => import("./../../../src/templates/ServiceCategory.tsx" /* webpackChunkName: "component---src-templates-service-category-tsx" */),
  "component---src-templates-service-detail-tsx": () => import("./../../../src/templates/ServiceDetail.tsx" /* webpackChunkName: "component---src-templates-service-detail-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/Services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-templates-team-detail-tsx": () => import("./../../../src/templates/TeamDetail.tsx" /* webpackChunkName: "component---src-templates-team-detail-tsx" */),
  "component---src-templates-teams-tsx": () => import("./../../../src/templates/Teams.tsx" /* webpackChunkName: "component---src-templates-teams-tsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/Footer/Footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-header-header-container-tsx": () => import("./../../../src/components/Header/Header.container.tsx" /* webpackChunkName: "slice---src-components-header-header-container-tsx" */)
}

